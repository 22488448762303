@import '../variables.scss';
@import 'node_modules/bootstrap/scss/mixins';

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;700;800&display=swap');

body {
  font-size: 14px !important;
  font-family: 'Nunito', sans-serif;
}

.bg-light .bg-white {
  color: $secondary !important;
  a {
    color: $secondary !important;
  }
}

.bg-primary .bg-secondary .bg-danger {
  color: white !important;
  a {
    color: white !important;
  }
}

// Font Size Classes
.font-18 {
  font-size: 18px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-10 {
  font-size: 10px !important;
}

.sidebar-logo {
  width: 120px;
}

.table a {
  text-decoration: underline;
}
@media only screen and (max-width: 767px) {
  .description-text {
    font-size: 12px;
  }
  .small-box h3 {
    font-size: 1.2rem;
  }
  .product-thumb {
    height: 113px !important;
  }
}
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.info-box {
  min-height: 65px;
}

.product-thumb {
  height: 113px;
}
.product .small-box {
  border-radius: 0 !important;
}
.small-box h3 {
  font-size: 1.1rem;
}

.small-box p {
  font-size: 0.8rem;
}

table thead {
  background-color: #f8f9fa !important;
}
table thead th {
  color: #343a40 !important;
}

.toast-wrapper {
  position: relative;
  min-height: 0px;
}

.toast {
  position: fixed;
  top: 70px;
  right: 0;
}

// OVERLAY

.myOverlay {
  border-radius: 0.25rem;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 50;

  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.imageOverlay {
  position: absolute;
  z-index: 51;
  height: 100%;
  top: 0;
  left: 28.5%;
  width: 300px;
  background: rgba(0, 0, 0, 0.4);
}

.page-wrapper {
  max-width: 1200px;
}

.addBtnIcon {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

a.disabled {
  pointer-events: none;
  cursor: default;
  background-color: #f1eded !important;
  border-color: #f1eded !important;
  color: #777 !important;
}

.list-feature {
  list-style: none;
  padding-left: 30px;
  min-width: 430px;
}

ol {
  padding-inline-start: 0;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

#uploadImgBtn {
  position: absolute;
  left: 44%;
  top: 42%;
  width: 100px;
  z-index: 52;
}

//Detailed Info Cards

.tile {
  width: 100%;
  border: 1px solid #ececec;
  background-color: #89989e;
  border-radius: 5px;
  box-shadow: 0px 2px 3px -1px rgba(151, 171, 187, 0.7);
  float: left;
  transform-style: preserve-3d;
}

.banner-img {
  padding: 5px 5px 0;
}

.banner-img img {
  width: 100%;
  border-radius: 5px;
}

.dates {
  border: 1px solid #ebeff2;
  border-radius: 5px;
  background-color: rgb(245, 245, 245);
  padding: 20px 0px;
  margin: 30px 20px;
  font-size: 14px;
  color: #5aadef;
  font-weight: 600;
  overflow: auto;
}
.dates div {
  float: left;
  width: 50%;
  text-align: center;
  position: relative;
}
.dates strong,
.stats strong {
  display: block;
  color: #adb8c2;
  font-size: 11px;
  font-weight: 700;
}
.dates span {
  width: 1px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  background: #ebeff2;
}
.stats {
  border-bottom: 1px solid #ebeff2;
  background: #fff;
  overflow: auto;
  padding: 15px 0;
  color: #59687f;
  font-weight: 600;
}
.stats div {
  border-right: 1px solid #ebeff2;
  width: 50%;
  font-size: 16px;
  float: left;
  text-align: center;
}
.stats.stats-2 div {
  border-right: 1px solid #ebeff2;
  width: 33.33333%;
  font-size: 16px;
  float: left;
  text-align: center;
}

.stats.stats-3 div {
  border-right: 1px solid #ebeff2;
  width: 100%;
  font-size: 14px;
  float: left;
  text-align: left;
  padding-left: 5%;
}

.stats div:nth-of-type(3) {
  border: none;
}
.stats.stats-2 div:nth-of-type(3) {
  border: none;
}
.stats.stats-2 div:nth-of-type(2) {
  border-right: 1px solid #ebeff2;
}

div.footer-card {
  text-align: right;
  position: relative;
  margin: 30px 5px;
}

div.footer-card a.Cbtn {
  padding: 10px 25px;
  background-color: #dadada;
  color: #666;
  margin: 10px 2px;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  border-radius: 3px;
}

div.footer-card a.Cbtn-primary {
  background-color: #5aadf2;
  color: #fff;
}

div.footer-card a.Cbtn-primary:hover {
  background-color: #7dbef5;
}

div.footer-card a.Cbtn-danger {
  background-color: #fc5a5a;
  color: #fff;
}

div.footer-card a.Cbtn-danger:hover {
  background-color: #fd7676;
}

.product-info-wrapper {
  margin: 10px;
}

//Detailed info card ends

#feature_modal {
  z-index: 2000;
}

// Query forms

.query-form {
  max-width: 220px;
}

.input-group-prepend .btn,
.input-group-append .btn {
  z-index: 0 !important;
}

.bg-dark-2 {
  background-color: #332d2d;
}

.bg-messages {
  background-color: #c0c7d0 !important;
}

table.dataTable.stripe tbody tr.odd,
table.dataTable.display tbody tr.odd {
  background-color: #f2f7ff;
}

.pdf-viewer {
  height: 80vh;
  width: 94vw;
}

//Product Card CSS

.wrapperX {
  min-width: 300px;
  height: auto;
  max-width: 600px;
  background: white;
  margin: auto;
  position: relative;
  overflow: hidden;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0;
  transform: scale(0.95);
  transition: box-shadow 0.5s, transform 0.5s;
  box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.2);
  &:hover {
    transform: scale(1);
    box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.2);
  }

  .containerX {
    width: 100%;
    height: auto;
    .top {
      height: 80%;
      -webkit-background-size: 100%;
      -moz-background-size: 100%;
      -o-background-size: 100%;
      background-size: 100%;
      img {
        height: 80% !important ;
        width: 100%;
      }
    }
    .bottom {
      width: 100%;
      height: 109px;
      transition: transform 0.5s;
      &.clicked {
        transform: translateX(-50%);
      }
      h1 {
        margin: 0;
        padding: 0;
      }
      p {
        margin: 0;
        padding: 0;
      }
      .left {
        height: 100%;
        width: 100%;
        background: #f4f4f4;
        position: relative;
        float: left;
        .details {
          padding: 20px;
          float: left;
          width: calc(70% - 40px);
          background-color: #fafafa;
        }
        .buy {
          float: right;
          width: 100%;
          height: 100%;
          background: #f1f1f1;
          transition: background 0.5s;

          i {
            font-size: 30px;
            padding: 30px;
            color: #254053;
            transition: transform 0.5s;
          }
          &:hover {
            background: #a6cdde;
          }
          &:hover i {
            transform: translateY(5px);
            color: #00394b;
          }
        }
      }
      .right {
        width: 50%;
        background: #a6cdde;
        color: white;
        float: right;
        height: 200%;
        overflow: hidden;
        .details {
          padding: 20px;
          float: right;
          width: calc(70% - 40px);
        }
        .done {
          width: calc(30% - 2px);
          float: left;
          transition: transform 0.5s;
          border-right: solid thin rgba(255, 255, 255, 0.3);
          height: 50%;
          i {
            font-size: 30px;
            padding: 30px;
            color: white;
          }
        }
        .remove {
          width: calc(30% - 1px);
          clear: both;
          border-right: solid thin rgba(255, 255, 255, 0.3);
          height: 50%;
          background: #bc3b59;
          transition: transform 0.5s, background 0.5s;
          &:hover {
            background: #9b2847;
          }
          &:hover i {
            transform: translateY(5px);
          }
          i {
            transition: transform 0.5s;
            font-size: 30px;
            padding: 30px;
            color: white;
          }
        }
        &:hover {
          .remove,
          .done {
            transform: translateY(-100%);
          }
        }
      }
    }
  }

  .inside {
    z-index: 9;
    background: #92879b;
    width: 140px;
    height: 140px;
    position: absolute;
    top: -70px;
    right: -70px;
    border-radius: 0px 0px 200px 200px;
    transition: all 0.5s, border-radius 2s, top 1s;
    overflow: hidden;
    .icon {
      position: absolute;
      right: 85px;
      top: 85px;
      color: white;
      opacity: 1;
    }
    &:hover {
      width: 100%;
      right: 0;
      top: 0;
      border-radius: 0;
      height: 80%;
      .icon {
        opacity: 0;
        right: 15px;
        top: 15px;
      }
      .contents {
        opacity: 1;
        transform: scale(1);
        transform: translateY(0);
      }
    }
    .contents {
      padding: 5%;
      opacity: 0;
      transform: scale(0.5);
      transform: translateY(-200%);
      transition: opacity 0.2s, transform 0.8s;
      table {
        text-align: left;
        width: 100%;
      }
      h1,
      p,
      table {
        color: white;
      }
      p {
        font-size: 13px;
      }
    }
  }
}

.prod-specs {
  background-color: #f4f4f4;
  font-style: italic;
}

.col-8.d-flex.text-light {
  background-color: #848484;
}

.bg-danger2 {
  background-color: #866969 !important;
}
.bg-success2 {
  background-color: #7b9482 !important;
}
.bg-primary2 {
  background-color: #7e8492 !important;
}

//END product card

//Print Button
.dt-buttons {
  margin: 15px 0;
  text-align: right;
}

.dt-button {
  border-radius: 5px;
  background-color: #7e8492;
  color: #fff;
  padding: 3px 15px;
}

// Print Area on Production Page

@media print {
  @page {
    size: A4 landscape;
  }
  body.production * {
    visibility: hidden;
    width: 100%;
  }
  // table {
  //   page-break-inside: avoid;
  // }
  .resize-on-print {
    width: 300px !important;
  }
  #section-to-print,
  #section-to-print * {
    visibility: visible;
    transform: scale(1);
    max-width: 100%;
    .MuiTable-root {
      table-layout: fixed !important;
    }
  }
  .section-not-to-print,
  .hide-on-print,
  .MuiToolbar-root,
  .section-not-to-print * {
    visibility: hidden !important;
  }

  .MuiToolbar-root,
  .MuiIcon-root,
  .MuiInputBase-input {
    visibility: hidden !important;
  }

  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;

    i.fa-edit {
      display: none;
    }
    thead {
      border: 2px solid #333;
    }
    tr {
      border: 2px solid #333;
      font-size: 8px !important;
    }
  }
}

.buttons-excel {
  background-color: #588272;
}

.bg-lightBlue {
  background-color: #cdd8e4 !important;
}

.bg-purple {
  background-color: #9e3e78 !important;
}

.bg-purple2 {
  background-color: #8d84c1 !important;
}

.add-button {
  &:hover {
    background-color: #3e8e41 !important;
  }
}

.order-button {
  width: 120px;
  height: 110px;
}

.progress {
  height: 2rem !important;
}

.basic-single {
  min-width: 400px;
}

select.form-control {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  width: 100%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
  background-position: 100%;
  background-repeat: no-repeat;
  border: 1px solid #ccc;
  padding: 0.5rem;
}
