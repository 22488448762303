@import '../../../variables.scss';
.order-button {
  font-size: 14px !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.product-img-thumb {
  height: 114px;
  width: 100% !important;
  object-fit: contain;
  background: #f5f5f5;
}

.production-details-edit-modal {
  .modal-dialog {
    max-width: 700px !important;
  }
  label {
    color: $danger;
  }
}
