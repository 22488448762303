@import '../../../variables.scss';

.color-picker-item {
  display: inline-block;
  min-width: 23px;
  min-height: 23px;
  border-radius: 8px;
  border: 1px solid #eee;
  line-height: 21px;
  background-color: rgb(230, 230, 230);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  color: rgb(37, 56, 88);
  font-weight: 400;
  font-size: 12px;

  &-label {
    padding: 0 5px;
  }
  &.green {
    background-color: lighten($success, 30);
    color: darken($success, 30);
  }
  &.blue {
    background-color: lighten($primary, 30);
    color: darken($primary, 30);
  }
  &.orange {
    background-color: lighten($warning, 10);
    color: darken($warning, 30);
  }
  &.red {
    background-color: lighten($danger, 30);
    color: darken($danger, 30);
  }
  &.white {
    background-color: lighten($light, 30);
    color: darken($light, 30);
  }
  &.gray {
    background-color: rgb(230, 230, 230);
    color: rgb(37, 56, 88);
  }
  &.black {
    background-color: $dark;
    color: #fff;
  }
}
