@import '../../../variables.scss';
@import 'node_modules/bootstrap/scss/mixins';

.content-wrapper {
  min-height: 88vh;

  &.menu-open {
    margin-left: 250px !important;
  }

  &.menu-close {
    margin-left: 0 !important;
  }

  @include media-breakpoint-down(md) {
    &.menu-open {
      margin-left: 0 !important;
    }
  }
}
