.floating-total-box {
  position: fixed;
  bottom: 25px;
  right: 25px;
  height: 60px;
  width: 140px;
  background-color: rgba(151, 9, 106, 0.9);
  color: white;
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.3);
  border: 2px solid #ccc;
  border-radius: 14px;
  z-index: 1000;
}
