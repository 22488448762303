.manufacture-component-card {
  font-size: 14px;
  min-height: 300px;
  &-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: 200px;
  }

  &-text {
    color: #666;

    strong {
      color: #333;
    }
  }
}

.manufacture-product-card {
  font-size: 14px;
  &-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    height: 200px;
  }

  &-text {
    color: #666;

    strong {
      color: #333;
    }
  }
}
