.image-edit-button {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.stock-count-indicator {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}
