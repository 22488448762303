.hide-on-print {
  @media print {
    display: none !important;
  }
}
.printable-table-planning {
  @media print {
    @page {
      size: landscape;
      margin: 1cm;
    }

    .content-wrapper {
      padding: 0 !important;
      margin: 0 !important;
    }

    .page-title,
    .manufacturers-filter {
      display: none !important;
    }

    .atolye-table {
      padding: 0 !important;
      border: none !important;
      font-size: 9px;

      .filter-blank {
        display: none !important;
      }

      .pagination-container {
        display: none !important;
      }

      thead {
        border: none !important;
        th {
          font-size: 9px;
          text-align: center;

          &:nth-child(5) {
            display: table-cell !important;
          }

          input,
          select,
          i {
            display: none !important;
          }
        }
      }
      td {
        color: #333 !important;
        border: 1px solid #ccc;

        &:nth-child(5) {
          display: table-cell !important;
        }

        span {
          font-size: 9px !important;
          border: none !important;
          color: #333 !important;
        }

        i {
          display: none !important;
        }

        img {
          width: 40px !important;
          max-height: 70px !important;
          border-radius: 0px !important;
        }
      }
    }
  }
}
