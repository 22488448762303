@import 'variables.scss';
.responsive-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 99999;
}
