@import '../../variables.scss';

.link-btn {
  background-color: transparent !important;
  border: none !important;
  color: $primary !important;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.authentication__night {
  background-color: $danger !important;
  padding: 20px;
  color: $white !important;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 25px;
    text-align: center;
  }
}
