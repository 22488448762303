@import '../../variables.scss';

.atolye-table {
  padding: 30px;
  border: 1px solid $lightPrimary;
  border-radius: 16px;
  background-color: #fff;

  thead {
    tr {
      border: none;
      height: 48px;
    }

    th {
      font-size: 12px;
      font-weight: 600;
      color: #181819;
    }
  }

  .pagination {
    padding: 0.5rem;
  }

  .badge {
    min-width: 85px;
  }

  .default-filter {
    height: 16px;
    width: 96px;
    border-radius: 4px;
    border: 1px solid #c4c4c4;
    font-size: 12px;
    line-height: 1;
    padding: 3px;
  }

  .filter-blank {
    height: 16px;
  }
}

// DatePicker
#date-picker-button {
  font-size: 12px !important;
  min-width: 130px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.3);

  .dropdown-item {
    font-size: 12px !important;
  }
}

.DateInput_input__small {
  font-size: 12px !important;
  &#id_end_date {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
}

.DateRangePickerInput {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.3);
}

.DateInput {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
