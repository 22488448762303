@import '../../../variables.scss';
@import 'node_modules/bootstrap/scss/mixins';

.main-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;

  &.menu-open {
    margin-left: 250px !important;
  }

  &.menu-close {
    margin-left: 0 !important;
  }

  @include media-breakpoint-down(md) {
    &.menu-open {
      margin-left: 0 !important;
    }

    &.menu-close {
      margin-left: 0 !important;
    }
  }
}

//Hide Scrollbar on menu

.main-sidebar::-webkit-scrollbar {
  display: none;
}

.main-sidebar {
  -ms-overflow-style: none;
}

aside {
  &.menu-open {
    width: 250px !important;
  }
  &.menu-close {
    width: 0 !important;
  }

  @include media-breakpoint-down(md) {
    &.menu-open {
      margin-left: 0 !important;
    }

    &.menu-close {
      margin-left: 0 !important;
    }
  }
}

//Sidebar Menu scroll fix

.main-sidebar {
  position: fixed !important;
  background-color: $dark !important;
  max-height: 100% !important;
  overflow-y: scroll;
  font-size: 14px;
  a {
    padding: 12px 16px;
    color: $light !important;
    &:hover {
      background-color: $overlayDark !important;
    }
  }

  .accordion {
    background-color: transparent !important;
    .accordion-item {
      background-color: transparent !important;
      border: none !important;
      .accordion-header {
        .accordion-button {
          background-color: transparent !important;
          font-size: 14px !important;
          color: $light !important;
        }
      }

      .accordion-body {
        background-color: lighten(#080338, 15%);
        ul {
          padding-inline-start: 0 !important;
        }
        li {
          list-style: none !important;
          padding-inline-start: 0 !important;
          a {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            text-decoration: none !important;
          }
        }
      }
    }
    p {
      margin-bottom: 0 !important;
    }
  }

  .nav-item {
    p {
      margin-left: 1.2rem !important;
    }
    background-color: transparent !important;
  }

  .nav-header {
    color: $inactive;
  }
}
