.hide-on-print {
  @media print {
    display: none !important;
  }
}

.printable-table {
  @media print {
    @page {
      size: landscape;
      margin: 1cm;
    }

    .content-wrapper {
      padding: 0 !important;
      margin: 0 !important;
      margin-top: -100px !important;
    }

    .atolye-table {
      border: none !important;
      padding: 0 !important;
      font-size: 9px;

      .filter-blank {
        display: none !important;
      }

      thead {
        border: none !important;
      }

      th {
        font-size: 9px;
        text-align: center;

        &:nth-child(5) {
          display: none !important;
        }

        input,
        select,
        i {
          display: none !important;
        }
      }

      td {
        color: #333 !important;

        &:nth-child(5) {
          display: none !important;
        }

        span {
          font-size: 9px !important;
          border: none !important;
          color: #333 !important;
        }

        img {
          width: 40px !important;
          max-height: 70px !important;
          border-radius: 0px !important;
        }
      }
    }
  }
}
