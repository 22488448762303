//Font Family
$font-family-base: 'Nunito', sans-serif;

// Colors
$primary: #503ff4;
$overlayPrimary: #d6dafe;
$secondary: #34447c;
$danger: #fc3776;
$overlayDanger: #fee4e9;
$info: #15aaff;
$success: #18b8ad;
$warning: #fecd82;
$dark: #080338;
$overlayDark: #161649;
$light: #f2f6fa;
$lightPrimary: #e7eafa;
$inactive: #8ba1bc;
$atolye: lighten(#cac0a3, 15%);
$black: #1d1d1d;
$ternary: #232b38;
$blueDark: #232b38;
$body-bg-secondary: #fafbfc;
$overlaySecondary: #d0b92c;
$blue: #2188c4;
$border-color: #e6e6e6;
$gray-title-sm: #bebebe;
$gray-main: #606060;
$body-bg-ternary: #252525;
$border-primary: #e8eaef;
$dark-shadow: #080708;
$light-shadow: #38393829;
$primary-input-border: #d3d3d3;
$dark-gray: #212529;
$secondary-gray: #6c7b8a;
$ternary-gray: #f4f4f4;

// ************* UTILITIES *************
$border-color: $border-primary;
$border-radius-sm: 0.3125rem;
$border-radius: 0.5rem;
$border-radius-lg: 0.75rem;
/* $box-shadow-sm: 0 .125rem .25rem rgba(#000000, .075);
$box-shadow: 0 0.3rem 1rem $dark-shadow;
$box-shadow-lg: 0 1rem 3rem rgba(#000000, .175); */
// ===================== END VARIABLE DEFINITIONS =====================

$blueDark: #232b38;

$theme-colors: (
  'primary': $primary,
  'overlayPrimary': $overlayPrimary,
  'lightPrimary': $lightPrimary,
  'secondary': $secondary,
  'overlaySecondary': $overlaySecondary,
  'info': $info,
  'success': $success,
  'danger': $danger,
  'warning': $warning,
  'light': $light,
  'dark': $dark,
  'ternary': $ternary,
  'blue': $blue,
  'blueDark': $blueDark,
  'dark-container': $body-bg-ternary,
  'dark-gray': $dark-gray,
  'secondary-gray': $secondary-gray,
  'ternary-gray': $ternary-gray,
  'inactive': $inactive,
  'atolye': $atolye,
  'black': $black
);

$body-bg: #fff;
$body-color: $secondary;

// Contrast Ratio
$min-contrast-ratio: 2.5;

@import 'node_modules/bootstrap/scss/bootstrap.scss';
