.image-full-size-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  .image-full-size {
    width: 80%;
    height: 80%;
    object-fit: contain;
  }
  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    color: white;
    cursor: pointer;
  }
}

.img-thumbnail {
  &:hover {
    cursor: zoom-in;
  }
}
